// extracted by mini-css-extract-plugin
export var bio = "frameCard-module--bio--g58N4";
export var bioGrid = "frameCard-module--bioGrid--Bfnqb";
export var cardWrapper = "frameCard-module--cardWrapper--NI4hZ";
export var downArrow = "frameCard-module--downArrow--qfP7n";
export var frameImage = "frameCard-module--frameImage--q7r2S";
export var linkOut = "frameCard-module--linkOut--PP23B";
export var location = "frameCard-module--location--6MjR4";
export var locationWrapper = "frameCard-module--locationWrapper--JSa9E";
export var logo = "frameCard-module--logo--kS6Ml";
export var logoWrapper = "frameCard-module--logoWrapper--9u6Pf";
export var more = "frameCard-module--more--ngKkD";
export var moreArrow = "frameCard-module--moreArrow--bZtqt";
export var secondLine = "frameCard-module--secondLine--GpSff";
export var visitWebsite = "frameCard-module--visitWebsite--oqyE0";
export var webLinkWrapper = "frameCard-module--webLinkWrapper--TjMGi";