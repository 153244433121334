// extracted by mini-css-extract-plugin
export var cornice = "tapestry-module--cornice--hl6BA";
export var corniceWrapper = "tapestry-module--corniceWrapper--GStZD";
export var filterFramesHeader = "tapestry-module--filterFramesHeader--ZIcVa";
export var headerGrid = "tapestry-module--headerGrid--9mP2a";
export var headerParagraph = "tapestry-module--headerParagraph--AzZG7";
export var headerParagraphWrapper = "tapestry-module--headerParagraphWrapper--Jrn-g";
export var noCorniceHeader = "tapestry-module--noCorniceHeader--Bh7AN";
export var redLine = "tapestry-module--redLine--3Cgwh";
export var tapestry = "tapestry-module--tapestry--flPUn";
export var tapestryGrid = "tapestry-module--tapestryGrid--Uuj6f";
export var visuallyHiddenHeader = "tapestry-module--visuallyHiddenHeader--cfoxq";
export var wrapper = "tapestry-module--wrapper--IiyxA";