import React from "react"
import * as tapestryStyles from "./tapestry.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const Tapestry = () => {
  return (
    <div className={tapestryStyles.wrapper}>
      <div className={tapestryStyles.headerGrid}>
        <div className={tapestryStyles.redLine}></div>
        <div className={tapestryStyles.tapestryGrid}>
          <StaticImage
            className={tapestryStyles.tapestry}
            style={{ display: `block`, marginTop: `-1.5em` }}
            src="../../images/frames/header/tapestryandframes.png"
            imgStyle={{ objectFit: `contain` }}
            placeholder="tracedSVG"
            layout="fullWidth"
            alt="Illustration of drapery and a vintage photo of a woman trying on frames"
          />
          <h1 className={tapestryStyles.visuallyHiddenHeader}>
            Fashion Eyewear
          </h1>
          <div className={tapestryStyles.headerParagraphWrapper}>
            <p className={tapestryStyles.headerParagraph}>
              We offer the finest eyewear with an emphasis on handcrafted frames
              by independent designers. Our staff is dedicated to helping you
              find just the right frame for fit, comfort, and, style; classic,
              modern, professional, or space-aged. See below for a list of our
              current collections.
            </p>
          </div>
        </div>
      </div>
      <div aria-hidden="true" className={tapestryStyles.noCorniceHeader}>
        <h2 className={tapestryStyles.filterFramesHeader}>
          FILTER FRAME SELECTIONS
        </h2>
      </div>
      <div className={tapestryStyles.corniceWrapper}>
        <StaticImage
          className={tapestryStyles.cornice}
          src="../../images/frames/header/cornice.png"
          alt=""
          placeholder="tracedSVG"
        />
        <h2 className={tapestryStyles.filterFramesHeader}>
          FILTER FRAME SELECTIONS
        </h2>
      </div>
    </div>
  )
}

export default Tapestry
