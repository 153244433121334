import React from "react"
import * as stockListStyles from "./stockList.module.scss"

const StockList = () => {
  return (
    <div className={stockListStyles.wrapper} id="stocklist" tabIndex={0}>
      <h2 className={stockListStyles.completeStocklists}>
        Complete Stocklists
      </h2>
      <div
        className={stockListStyles.listWrapper}
        role="group"
        aria-label="Stocklist Card"
      >
        <p
          className={stockListStyles.listText}
          aria-label="University City Stock"
        >
          <span className={stockListStyles.listLocation}>
            <h3>modern eye university city</h3> -
          </span>{" "}
          Ahlem, Andy Wolf, Anne et Valentine, Bevel, Blake Kuwahara, Caroline
          Abram, Folc, Francis Klein, Kirk & Kirk, Henau, iGreen, l.a. Eyeworks,
          Nina Mûr, Matsuda, Mykita, Oakley, Rapp Ltd, Salt, Talla, Vinylize,
          Maui Jim, Ray Ban, Rudy Project Sunglasses, Woow
        </p>
        <p className={stockListStyles.listText} aria-label="Center City Stock">
          <span className={stockListStyles.listLocation}>
            <h3>modern eye center city</h3> -
          </span>{" "}
          141 Eyewear, Ahlem, Andy Wolf, Article One, Bevel, Blake Kuwahara,
          Brett, Dandy’s, Denon, Ethnicity, l.a. Eyeworks, Maui Jim, Mykita,
          Noego, Oliver Goldsmith, Robert LaRoche, Roger, Rolf, Rudy Project,
          Salt, Say-oH, Struktur, Vinylize
        </p>
      </div>
    </div>
  )
}

export default StockList
