import React from "react"
import * as frameCardStyles from "./frameCard.module.scss"
import { Link, navigate } from "gatsby"
import { FiExternalLink } from "react-icons/fi"
import { IoIosArrowDown } from "react-icons/io"
import { GatsbyImage } from "gatsby-plugin-image"

const FrameCard = ({
  id,
  slug,
  logo,
  title,
  location,
  image,
  imageDescription,
  website,
  excerpt,
}) => {
  //this adds click listeners for mouse users in order to reduce work for keyboard users
  const navigateToItemPage = () => {
    // navigate to `/frames${slug}`
    navigate(`/frames${slug}`)
  }

  return (
    <li
      className={frameCardStyles.cardWrapper}
      key={id}
      aria-label="Frame Card"
    >
      <Link to={`/frames${slug}`} title={title}>
        <div className={frameCardStyles.logoWrapper}>
          <GatsbyImage
            className={frameCardStyles.logo}
            image={logo}
            imgStyle={{
              objectFit: `contain`,
              display: `block`,
              marginTop: `auto`,
              marginBottom: `auto`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            alt={`${title} logo`}
          />
        </div>
        <div className={frameCardStyles.locationWrapper}>
          <small className={frameCardStyles.location}>{location}</small>
        </div>
        <div>
          <GatsbyImage
            imgStyle={{ objectFit: `contain` }}
            className={frameCardStyles.frameImage}
            image={image}
            alt={imageDescription}
          />
        </div>
        <div aria-hidden="true">
          <div className={frameCardStyles.secondLine} aria-hidden="true"></div>
        </div>
        <div style={{ textDecoration: "none" }} to={`/frames${slug}`}>
          <div className={frameCardStyles.bioGrid}>
            <p className={frameCardStyles.bio}>{excerpt}</p>
            <div
              className={frameCardStyles.moreArrow}
              to={`/frames${slug}`}
              title={title}
            >
              <span className={frameCardStyles.more} to={`/frames${slug}`}>
                More
              </span>
              <div to={`/frames${slug}`} className={frameCardStyles.downArrow}>
                <IoIosArrowDown />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default FrameCard
